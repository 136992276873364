

const SplashPage = () => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center px-8">
      <img src="/img/logo/logo-black.png" alt="" className="w-auto" />
    </div>
  )
}

export default SplashPage